import React, { useContext, useEffect } from "react";
import jszip from "jszip";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CloudImage from "components/CloudImage/CloudImage";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";
import { transform } from "../../utils/ImageUtils";

import fakeRoutes from "../../fakeRoutes.json";
import sentences from "../../sentences.json";
import keywords from "../../keywords.json";

import iconCertification from "../../img/icon_certification.png";
import iconIa from "../../img/icon_ia.svg";

const styles = {
  formControl: {
    margin: "10px 0",
    width: "500px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  formControlColumn: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  selected: {
    height: "50px",
  },
  dangerButtonCategories: {
    height: "100%",
    background: "#d62546",
    color: "#fff",
  },
  postImgContainer: {
    position: "relative",
    top: "0px",
    width: "300px",
    minWidth: "150px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: "#111",
    margin: "1px",
  },
  postImgContainerSmall: {
    position: "relative",
    top: "0px",
    width: "100px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: "#111",
    margin: "1px",
  },
  postImg: {
    width: "250px",
    height: "250px",
    objectFit: "cover",
  },
  postDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontSize: "22px",
  },
  tag: {
    color: "skyblue",
    marginBottom: "5px",
  },
  bold: {
    fontWeight: "bold",
  },
  yes: {
    color: "#06A94D",
    fontWeight: "bold",
  },
  no: {
    color: "#B30000",
    fontWeight: "bold",
  },
  successButton: {
    background: "#32CD32",
    color: "#fff",
    marginRight: "10px",
    marginTop: "10px",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    marginRight: "10px",
    marginTop: "10px",
  },
  dangerButton: {
    background: "#d62546",
    color: "#fff",
    marginRight: "10px",
    marginTop: "10px",
  },
  warningButton: {
    background: "#FFA500",
    color: "#fff",
    marginRight: "10px",
    marginTop: "10px",
  },
  deepaiContainerSuccess: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    padding: "5px",
    borderRadius: "5px",
    background: "#32CD32",
    color: "#fff",
    fontSize: "16px",
  },
  deepaiContainerFail: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    padding: "5px",
    borderRadius: "5px",
    background: "#d52a50",
    color: "#fff",
    fontSize: "16px",
  },
  deepaiContainerInactive: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    padding: "5px",
    borderRadius: "5px",
    background: "#ddd",
    color: "#666",
    fontSize: "16px",
  },
  poiter: {
    cursor: "pointer",
  },
  iconCertification: {
    height: "25px",
    width: "25px",
    position: "absolute",
    right: "20px",
    top: "20px",
  },
};

const useStyles = makeStyles(styles);

export default function List(props) {
  const classes = useStyles();
  const context = useContext(AppContext);

  const authService = new AuthService();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [tr, setTr] = React.useState({});
  const [exportCount, setExportCount] = React.useState(5);
  const [
    initialContentCategoriesOptions,
    setInitialContentCategoriesOptions,
  ] = React.useState([]);
  const [
    contentCategoriesOptions,
    setContentCategoriesOptions,
  ] = React.useState([]);
  const [contentCategoriesMap, setContentCategoriesMap] = React.useState({});
  const [deepAiLoading, setDeepAiLoading] = React.useState(null);
  const [likeCountToAdd, setLikeCountToAdd] = React.useState(1);
  const [src, setSrc] = React.useState({});
  const [score, setScore] = React.useState(0);
  const [isIa, setIsIa] = React.useState(0);
  const [keepScore, setKeepScore] = React.useState(false);
  const [defaultSrc, setDefaultSrc] = React.useState({});
  const [format, setFormat] = React.useState("normal");
  const [rand, setRand] = React.useState(123456);
  const [loading, setLoading] = React.useState(false);

  const { key, post, index, approved, onPostChanged, onPostRemoved } = props;

  useEffect(() => {
    loadTranslations();
    loadContentCategories();
    setSrc(post.src);
    setIsIa(post.ia);

    if (post.score) {
      setScore(post.score);
    }
    if (post.keepScore) {
      setKeepScore(post.keepScore);
    }
    setDefaultSrc(post.src);
  }, []);

  const loadTranslations = () => {
    authService.setUrl(context.apiUrl + context.translationsPath);
    authService.request("get", { country: "EN", type: "site" }, (response) => {
      const { data } = response;
      if (data.length > 0) {
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }
    });
  };

  const addLikes = (postId, index) => {
    authService.setUrl(context.apiUrl + context.newsPath + "like");

    let data = {
      postId,
      count: Number(likeCountToAdd),
    };

    authService.request("post", data, () => {
      post.nbLike = Number(post.nbLike) + Number(likeCountToAdd);
      onPostChanged(post, index);
    });
  };

  const loadContentCategories = () => {
    authService.setUrl(context.apiUrl + context.categoriesPath + "content");
    authService.request("get", {}, (response) => {
      const { data } = response;

      if (Array.isArray(data)) {
        let dataSorted = data.sort(
          (a, b) => a.popularityOrder - b.popularityOrder
        );

        dataSorted.forEach((category, index) => {
          category.initialIndex = index;
        });

        setInitialContentCategoriesOptions([...dataSorted]);

        let selectedCategories = [];
        let unselectedCategories = [];

        dataSorted.forEach((category) => {
          if (post.categories && post.categories.includes(category._id)) {
            selectedCategories.push(category);
          } else {
            unselectedCategories.push(category);
          }
        });

        dataSorted = [...selectedCategories, ...unselectedCategories];

        let contentCategoriesMap = {};
        dataSorted.forEach((category) => {
          if (category) {
            contentCategoriesMap[category._id] = category;
          }
        });

        setContentCategoriesOptions(dataSorted);
        setContentCategoriesMap(contentCategoriesMap);
      }
    });
  };

  const approvePost = (postId, index) => {
    authService.setUrl(context.apiUrl + context.postsPath + "approve");
    authService.request("put", { postId, status: "approved" }, () => {
      onPostRemoved(index);
    });
  };

  const nudity = (postId, index) => {
    authService.setUrl(context.apiUrl + context.postsPath + "approve");
    authService.request("put", { postId, status: "nudity" }, () => {
      onPostRemoved(index);
    });
  };

  const saveBlob = (blob, filename) => {
    let blobUrl = URL.createObjectURL(blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = blobUrl;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  const fbExport = async (post) => {
    setLoading(true);

    let text = getFormatedText(keywords);

    let sentencesFormated = getFormatedText(sentences);
    text += `\n${sentencesFormated}`;

    let routesFormated = await getUserRoutes(post, exportCount);
    text += `\n${routesFormated}`;

    let zip = new jszip();

    zip.file("export.txt", text);

    getImages(post, (blobs) => {
      blobs.forEach((blob, i) => {
        zip.file(`${post.userName}_${post._key}_${i}.png`, blob);
      });
      zip.generateAsync({ type: "blob" }).then((blob) => {
        setLoading(false);
        saveBlob(blob, `${post._key}.zip`);
      });
    });
  };

  const getFormatedText = (sources) => {
    let result = "{";
    sources.forEach((text, index) => {
      if (index > 0) {
        result += "|\n";
      }
      result += text;
    });
    result += "}";
    return result;
  };

  const getUserRoutes = (post, limit) => {
    return new Promise((resole, reject) => {
      let userData = {
        userId: post.userId,
        count: limit,
      };

      authService.setUrl(context.apiUrl + context.usersPath + "alias");
      authService.request("get", userData, (response) => {
        let aliasList = response.data;
        let routes = "{";
        aliasList.forEach((alias, index) => {
          if (index > 0) {
            routes += "|\n";
          }
          routes += `https://uncove.com/@${alias}`;
        });
        routes += "}";

        resole(routes);
      });
    });
  };

  const getImages = (post, callback) => {
    let image = new Image();
    let blobs = [];
    image.crossOrigin = "anonymous";
    image.onload = async function() {
      for (let i = 0; i < exportCount; ++i) {
        let c = document.createElement("canvas");
        c.width = this.width;
        c.height = this.height - i;
        let ctx = c.getContext("2d");
        ctx.drawImage(this, 0, 0, this.width, this.height - i);
        let blob = await canvasToBlob(c);
        blobs.push(blob);
      }
      callback(blobs);
    };
    image.src = post.src.normal;
  };

  const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(resolve);
    });
  };

  const handleCategoryChanged = (categories, post, index) => {
    const previousCategories = post.categories || [];
    const newCategories = categories;

    const addedCategories = newCategories.filter(
      (item) => !previousCategories.includes(item)
    );

    const removedCategories = previousCategories.filter(
      (item) => !newCategories.includes(item)
    );

    let savedCategories = post.categories;
    post.categories = categories;
    onPostChanged(post, index);

    let newContentCategoriesOptions = [...contentCategoriesOptions];

    if (addedCategories.length > 0) {
      addedCategories.forEach((categoryId) => {
        const index = newContentCategoriesOptions.findIndex(
          (c) => c._id === categoryId
        );
        const [category] = newContentCategoriesOptions.splice(index, 1);
        newContentCategoriesOptions.unshift(category);
      });
    }

    if (removedCategories.length > 0) {
      removedCategories.forEach((categoryId) => {
        const index = newContentCategoriesOptions.findIndex(
          (c) => c._id === categoryId
        );
        const [category] = newContentCategoriesOptions.splice(index, 1);
        const initialIndex = initialContentCategoriesOptions.findIndex(
          (c) => c._id === categoryId
        );
        newContentCategoriesOptions.splice(initialIndex, 0, category);
      });
    }
    setContentCategoriesOptions(newContentCategoriesOptions);

    let postData = {
      postId: post._id,
      description: post.description,
      isPrivate: post.isPrivate,
      tags: post.tags,
      categories,
    };
    authService.setUrl(context.apiUrl + context.postsPath);
    authService.request("put", postData, (response) => {
      if (response.data.error) {
        post.categories = savedCategories;
        onPostChanged(post, index);
      }
    });
  };

  const updateVisibility = async (post, index) => {
    let mime = "image/jpeg";

    const response = await fetch(src.normal);
    const normalBlob = await response.blob();

    transform(
      context,
      normalBlob,
      750,
      context.blurRadius,
      false,
      "",
      mime,
      (blurredBlob) => {
        updatePost(post, blurredBlob, index);
        // Uncomment to open blurred file in new tab
        // window.open(URL.createObjectURL(blurredBlob));
      }
    );
  };

  const updatePost = (post, blurredBlob, index) => {
    // alert(JSON.stringify(post, null, 4));

    const { photoId, isPrivate, description, categories, type, tags } = post;

    let formData = null;
    if (type === "image") {
      formData = new FormData();
      formData.append("blurredFile", blurredBlob);
      formData.set("photoId", photoId);
      formData.set("isPrivate", !isPrivate);
      formData.set("tags", JSON.stringify(tags));
      authService.setContentType("multipart/form-data");
    } else {
      formData = {
        isPrivate: !isPrivate,
        tags,
      };
      authService.setContentType("application/json");
    }


    let path = type === "image" ? context.photosPath : context.videosPath;

    authService.setUrl(context.apiUrl + path);
    authService.request("put", formData, () => {
      let postData = {
        postId: post._id,
        description,
        isPrivate: !isPrivate,
        categories,
        tags,
      };

      authService.setContentType("application/json");
      authService.setUrl(context.apiUrl + context.postsPath);
      authService.request("put", postData, () => {
        post.isPrivate = !isPrivate;
        onPostChanged(post, index);
      });
    });
  };

  const deletePost = (post, index) => {
    var authService = new AuthService(context.apiUrl + context.postsPath);
    authService.setUrl(context.apiUrl + context.postsPath);
    authService.request("delete", { postId: post._id }, () => {
      onPostRemoved(index);
    });
  };

  const disableRobotVerification = (post, index) => {
    var authService = new AuthService(context.apiUrl + context.postsPath);
    authService.setUrl(
      context.apiUrl + context.postsPath + "bypassRobotResult"
    );
    let data = {
      postId: post._id,
      disableRobotVerification: !post.disableRobotVerification,
    };
    authService.request("put", data, () => {
      post.disableRobotVerification = !post.disableRobotVerification;
      onPostChanged(post, index);
    });
  };

  const callDeepAi = (post, index) => {
    setDeepAiLoading(post._id);
    var authService = new AuthService(context.apiUrl + context.postsPath);
    authService.setUrl(context.apiUrl + context.postsPath + "safety");
    authService.request("put", { postId: post._id, limit: 1 }, (response) => {
      setDeepAiLoading(null);
      let result = response.data.length > 0 ? response.data[0] : null;
      let unsafeCoefficient = result ? result.unsafeCoefficient : null;
      let unsafeContentDetected = result ? result.unsafeContentDetected : null;
      let categories = result ? result.categories : null;
      let categoriesAsked = result ? result.categoriesAsked : null;
      let approved = result ? result.approved : false;

      post.approved = approved;
      post.unsafeCoefficient = unsafeCoefficient;
      post.unsafeContentDetected = unsafeContentDetected;
      post.categories = categories;
      post.categoriesAsked = categoriesAsked;
      onPostChanged(post, index);
    });
  };

  const fixFormat = () => {
    src[format] = format === "medium" ? src.normal : src.medium;
    setSrc(src);
    setRand(Math.round(Math.random() * 10000));

    authService.setUrl(context.apiUrl + context.photosPath + "format");
    let data = {
      photoId: post.photoId,
      format,
    };
    authService.request("put", data, (response) => {});
  };

  const getDefaultSrc = () => {
    console.log("defaultSrc", defaultSrc);
    alert(JSON.stringify(defaultSrc, null, 4));
  };

  const play = (video) => {
    window.open(video, "_blank");
  };

  const updateScore = (score) => {
    console.log("setScore", score);
    setScore(score);

    authService.setUrl(context.apiUrl + context.postsPath + "score");
    let data = {
      postId: post._id,
      score: Number(score),
      keepScore,
    };
    authService.request("put", data, () => {});
  };

  const updateKeepScore = (keepScore) => {
    console.log("setKeepScore", score);
    setKeepScore(keepScore);

    authService.setUrl(context.apiUrl + context.postsPath + "score");
    let data = {
      postId: post._id,
      score: Number(score),
      keepScore,
    };
    authService.request("put", data, () => {});
  };

  let formatOptions = ["normal", "medium", "small"];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "white",
    border: "2px solid #2D2D2D",
    boxShadow: 24,
    width: "80px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  };

  const handleSwitchAi = (post) => {
    setIsIa(true);

    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.usersPath + "ia");
    authService.request(
      "put",
      {
        userId: post.userId,
        value: true,
      },
      () => {}
    );
  };

  return (
    <>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <label>Loading</label>
        </Box>
      </Modal>

      <Card key={key}>
        <Box p={0} padding="20px">
          {(!isIa && post.userVerified) && (
            <img
              className={classes.iconCertification}
              src={iconCertification}
            />
          )}
          {isIa && (
            <img
              className={classes.iconCertification}
              src={iconIa}
            />
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl>
                <Select
                  style={{
                    color: "#555",
                    fontSize: "18px",
                    width: "100%",
                    height: "40px",
                    marginBottom: "10px",
                  }}
                  value={format}
                  variant="outlined"
                  onChange={(evt) => setFormat(evt.target.value)}
                >
                  {formatOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      style={{
                        height: "40px",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box p={0} display="flex" flexDirection="row">
                <div className={classes.postImgContainer}>
                  <CloudImage
                    key={src && src[format] ? src[format] + rand : post._id}
                    className={classes.postImg}
                    src={src && src[format] ? src[format] : null}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                    crossOrigin="anonymous"
                  />
                </div>
                <Box p={0} display="flex" flexDirection="column">
                  <div className={classes.postImgContainerSmall}>
                    <CloudImage
                      key={src && src.medium ? src.medium + rand : post._id}
                      className={classes.postImg}
                      src={src ? src.medium : null}
                      defaultHeight="auto"
                      defaultWidth="100%"
                      alt="thumb"
                      crossOrigin="anonymous"
                    />
                  </div>
                  <div className={classes.postImgContainerSmall}>
                    <CloudImage
                      key={src && src.small ? src.small + rand : post._id}
                      className={classes.postImg}
                      src={src ? src.small : null}
                      defaultHeight="auto"
                      defaultWidth="100%"
                      alt="thumb"
                      crossOrigin="anonymous"
                    />
                  </div>
                  {post.isPrivate && (
                    <div className={classes.postImgContainerSmall}>
                      <CloudImage
                        key={src && src.blur ? src.blur + rand : post._id}
                        className={classes.postImg}
                        src={src ? src.blur : null}
                        defaultHeight="auto"
                        defaultWidth="100%"
                        alt="thumb"
                        crossOrigin="anonymous"
                      />
                    </div>
                  )}
                </Box>
              </Box>

              <Button
                className={classes.warningButton}
                onClick={() => fixFormat()}
                variant="contained"
              >
                Fix format {format}
              </Button>

              <Button
                className={classes.infoButton}
                onClick={() => getDefaultSrc()}
                variant="contained"
              >
                show Default
              </Button>
            </GridItem>

            <GridItem xs={12} sm={12} md={8}>
              <div className={classes.postDetails}>
                <label className={classes.tag}>
                  {post &&
                    post.tags &&
                    post.tags.map((tag) => (
                      <span key={tag}>{"#" + tag + " "}</span>
                    ))}
                </label>

                <label>
                  User name :{" "}
                  <strong>{post.userName ? post.userName : ""}</strong>
                </label>

                <label>
                  Date : <strong>{new Date(post.date).toLocaleString()}</strong>{" "}
                </label>

                <label>
                  Amount : <strong>{post.amount ? post.amount : 0} €</strong>
                </label>

                <label>
                  Description : <strong>{post.description}</strong>
                </label>

                <label>
                  Media type : <strong>{post.type}</strong>
                </label>

                <label>
                  Like : <strong>{post.nbLike}</strong>
                </label>

                <label>
                  ID : <strong>{post._id}</strong>
                </label>

                {post.duration && (
                  <>
                    <label>
                      Duration : <strong>{post.duration}</strong>
                    </label>
                  </>
                )}

                <label>
                  Private :{" "}
                  <i className={post.isPrivate ? classes.yes : classes.no}>
                    {post.isPrivate ? "Yes" : "No"}
                  </i>
                </label>

                <label>
                  Need robot check :{" "}
                  <i className={post.needRobotCheck ? classes.yes : classes.no}>
                    {post.needRobotCheck ? "Yes" : "No"}
                  </i>
                </label>

                <label>
                  Approved posts :{" "}
                  <strong>{post.approvedPosts ? post.approvedPosts : 0}</strong>
                </label>

                {post.categoriesAsked && post.categoriesAsked.length && (
                  <label>
                    Categories asked :{" "}
                    {post.categoriesAsked.map((e, index) => (
                      <span className={classes.bold}>
                        {tr.categoryOption && contentCategoriesMap[e]
                          ? tr.categoryOption[contentCategoriesMap[e].keyname]
                          : ""}
                        {index !== post.categoriesAsked.length - 1 ? " , " : ""}
                      </span>
                    ))}
                  </label>
                )}

                <FormControl
                  className={
                    isMobile ? classes.formControlColumn : classes.formControl
                  }
                >
                  <Select
                    multiple
                    style={{
                      color: "#555",
                      fontSize: "18px",
                      width: "280px",
                      height: "50px",
                    }}
                    value={post.categories ? post.categories : []}
                    variant="outlined"
                    onChange={(evt) =>
                      handleCategoryChanged(evt.target.value, post, index)
                    }
                    renderValue={(selected) =>
                      selected
                        .map((e) =>
                          tr.categoryOption && contentCategoriesMap[e]
                            ? tr.categoryOption[contentCategoriesMap[e].keyname]
                            : ""
                        )
                        .join(", ")
                    }
                  >
                    {contentCategoriesOptions.map((category) => (
                      <MenuItem
                        key={category._id}
                        value={category._id}
                        className={classes.selected}
                      >
                        <Checkbox
                          checked={
                            post.categories &&
                            post.categories.indexOf(category._id) > -1
                          }
                        />
                        <p className={classes.category}>
                          {tr.categoryOption && category
                            ? tr.categoryOption[category.keyname]
                            : ""}
                        </p>
                      </MenuItem>
                    ))}
                  </Select>

                  <Button
                    className={classes.dangerButtonCategories}
                    onClick={() => handleCategoryChanged([], post, index)}
                    variant="contained"
                  >
                    Clear
                  </Button>
                </FormControl>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  margin="5px 0"
                  padding={0}
                >
                  <label style={{ padding: "0px", marginRight: "5px" }}>
                    Score
                  </label>
                  <TextField
                    size="small"
                    style={{ width: 50, marginLeft: "10px" }}
                    label=""
                    type="number"
                    variant="standard"
                    value={score}
                    onChange={(evt) => updateScore(evt.target.value)}
                  />
                  <Checkbox
                    size="small"
                    onClick={() => updateKeepScore(!keepScore)}
                    style={{ padding: "0px", marginLeft: "5px" }}
                    checked={keepScore}
                  />
                </Box>

                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  p={1}
                  m={1}
                  bgcolor="background.paper"
                  margin="10px 0"
                  padding={0}
                >
                  {post.type === "video" && (
                    <Button
                      className={classes.infoButton}
                      onClick={() => play(post.video)}
                      variant="contained"
                    >
                      Play
                    </Button>
                  )}

                  {!approved && (
                    <Button
                      className={classes.successButton}
                      onClick={() => approvePost(post._id, index)}
                      variant="contained"
                    >
                      Approve
                    </Button>
                  )}

                  {!approved && (
                    <Button
                      className={classes.warningButton}
                      onClick={() => nudity(post._id, index)}
                      variant="contained"
                    >
                      Nudity
                    </Button>
                  )}

                  {post.isPrivate && (
                    <Button
                      className={classes.infoButton}
                      onClick={() => updateVisibility(post, index)}
                      variant="contained"
                    >
                      Move to public
                    </Button>
                  )}

                  {!post.isPrivate && (
                    <Button
                      className={classes.infoButton}
                      onClick={() => updateVisibility(post, index)}
                      variant="contained"
                    >
                      Move to private
                    </Button>
                  )}

                  {!isIa && (
                    <Button
                      className={classes.warningButton}
                      onClick={() => {
                        handleSwitchAi(post);
                      }}
                      variant="contained"
                    >
                      Switch IA
                    </Button>
                  )}

                  <Button
                    className={classes.infoButton}
                    onClick={() => callDeepAi(post, index)}
                    variant="contained"
                  >
                    Deep AI
                  </Button>

                  <Button
                    className={classes.dangerButton}
                    onClick={() => deletePost(post, index)}
                    variant="contained"
                  >
                    Delete
                  </Button>
                </Box>

                {!post.isPrivate && !post.videoId && (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    p={1}
                    m={1}
                    bgcolor="background.paper"
                    margin="10px 0"
                    padding={0}
                  >
                    <TextField
                      margin="dense"
                      size="small"
                      style={{ height: 25, width: 50 }}
                      label=""
                      type="number"
                      variant="standard"
                      value={exportCount}
                      onChange={(evt) => setExportCount(evt.target.value)}
                    />

                    <Button
                      className={classes.infoButton}
                      style={{ marginLeft: "10px" }}
                      onClick={() => fbExport(post)}
                      variant="contained"
                    >
                      Export
                    </Button>
                  </Box>
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  p={1}
                  m={1}
                  bgcolor="background.paper"
                  margin="10px 0"
                  padding={0}
                >
                  <TextField
                    margin="dense"
                    size="small"
                    style={{ height: 25, width: 50 }}
                    label=""
                    type="number"
                    variant="standard"
                    value={likeCountToAdd}
                    onChange={(evt) => setLikeCountToAdd(evt.target.value)}
                  />

                  <Button
                    className={classes.infoButton}
                    style={{ marginLeft: "10px" }}
                    onClick={() => addLikes(post._id, index)}
                    variant="contained"
                  >
                    Add {likeCountToAdd} Like{likeCountToAdd > 1 ? "s" : ""}
                  </Button>
                </Box>

                {deepAiLoading === post._id && (
                  <CircularProgress color="secondary" />
                )}

                {deepAiLoading !== post._id &&
                  (post.unsafeContentDetected || post.unsafeCoefficient) && (
                    <div
                      className={
                        post.disableRobotVerification
                          ? classes.deepaiContainerInactive
                          : post.approved
                          ? classes.deepaiContainerSuccess
                          : classes.deepaiContainerFail
                      }
                    >
                      <b>DEEPAI</b>
                      {post.unsafeContentDetected.map((content) => (
                        <span>{content.name}</span>
                      ))}
                      {post.unsafeCoefficient > 0 && (
                        <span>
                          Unsafe coefficient :{" "}
                          {Math.round(post.unsafeCoefficient * 100)}
                        </span>
                      )}
                      {post.approved && (
                        <>
                          <span>Verified</span>
                        </>
                      )}
                      {post.approved !== undefined && (
                        <span>Approved : {post.approved ? "Yes" : "No"}</span>
                      )}

                      <Box
                        p={0}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        margin="10px 0"
                        padding={0}
                      >
                        <Checkbox
                          size="small"
                          onClick={() => disableRobotVerification(post, index)}
                          checked={post.disableRobotVerification}
                        />
                        <p>Disable robot result</p>
                      </Box>
                    </div>
                  )}
              </div>
            </GridItem>
          </GridContainer>
        </Box>
      </Card>
    </>
  );
}
